import React, { useState } from 'react';
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, ChevronRightIcon } from "@chakra-ui/icons";

export default function AddContacts() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <Box p={6} borderWidth={1} borderRadius="lg" boxShadow="sm">
      <Button 
        onClick={handleOpenModal} 
        colorScheme="blue" 
        mb={4}
        display={'flex'}
        marginLeft={'auto'}
        marginBottom={5}
      >
        Configure Email Account
      </Button>
      
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Title</Th>
              <Th>Company</Th>
              <Th>Industry</Th>
              <Th>Email</Th>
              <Th>Last Send Date</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Example Name</Td>
              <Td>Example Title</Td>
              <Td>example@example.com</Td>
              <Td>smtp@example.com</Td>
              <Td>smtp.example.com</Td>
              <Td>smtp.example.com</Td>
              <Td>
                <IconButton aria-label="Edit" icon={<EditIcon />} mr={2} />
                <IconButton aria-label="Delete" icon={<DeleteIcon />} />
              </Td>
            </Tr>
            {/* Add more rows as needed */}
          </Tbody>
        </Table>
      </TableContainer>

      <Button
        display={"flex"}
        justifyContent="flex-end"
        marginLeft="auto"
        width="auto"
        colorScheme="blue"
        mt={4}
        rightIcon={<ChevronRightIcon />}
      >
        Save & Next
      </Button>

      {/* Configure Email Account Modal */}
      <Modal isOpen={isOpen} onClose={handleCloseModal} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Configure Email Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input placeholder="Search..." mb={4} />
            <Box overflowY={'auto'}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Title Name</Th>
                  <Th>From Email</Th>
                  <Th>SMTP Email</Th>
                  <Th>SMTP Host</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Example Name</Td>
                  <Td>Example Title</Td>
                  <Td>from@example.com</Td>
                  <Td>smtp@example.com</Td>
                  <Td>smtp.host.com</Td>
                </Tr>
                {/* Add more rows as needed */}
              </Tbody>
            </Table>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Save
            </Button>
            <Button variant="ghost" onClick={handleCloseModal}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

import React, { useState } from "react";
import {
  Box,
  Button,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Switch,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon, CopyIcon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

// Define the props type for StepIcon
interface StepIconProps {
  stepCount: number;
}

// Custom Icon Component
const StepIcon: React.FC<StepIconProps> = ({ stepCount }) => (
  <Box
    borderRadius="50%"
    backgroundColor="#3965FF"
    color="#fff"
    display={"flex"}
    margin={"auto"}
    alignItems={"center"}
    justifyContent={"center"}
    fontWeight={"bold"}
    w={"100%"}
    h={"100%"}
  >
    {stepCount}
  </Box>
);

    const steps = [
      { waitTime: '5 min', subject: 'Subject A', emodProfile: 'Profile 1', body: 'Body content A', active: true },
      { waitTime: '10 min', subject: 'Subject B', emodProfile: 'Profile 2', body: 'Body content B', active: false },
      { waitTime: '15 min', subject: 'Subject C', emodProfile: 'Profile 3', body: 'Body content C', active: true }
    ];
export default function PreviewSteps() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");
  const [waitTime, setWaitTime] = useState("");
  const [emodProfile, setEmodProfile] = useState("");
  const [steps, setSteps] = useState<
    {
      waitTime: string;
      subject: string;
      emodProfile: string;
      body: string;
      date: string;
      active: boolean;
    }[]
  >([]);

  const handleSave = () => {
    // Add the new step to the steps array
    setSteps([
      ...steps,
      {
        waitTime,
        subject,
        emodProfile,
        body,
        date: new Date().toLocaleDateString(), // Add current date for demonstration
        active: true, // Default to active
      },
    ]);
    // Close the modal
    onClose();
    // Clear the form inputs
    setBody("");
    setSubject("");
    setWaitTime("");
    setEmodProfile("");
  };

  const handleToggle = (index: number) => {
    setSteps(
      steps.map((step, i) =>
        i === index ? { ...step, active: !step.active } : step
      )
    );
  };

  const handleEdit = (index: number) => {
    const step = steps[index];
    setWaitTime(step.waitTime);
    setSubject(step.subject);
    setEmodProfile(step.emodProfile);
    setBody(step.body);
    onOpen();
    // Remove the current step to replace it after editing
    setSteps(steps.filter((_, i) => i !== index));
  };

  const handleDuplicate = (index: number) => {
    setSteps([...steps, { ...steps[index] }]);
  };

  const handleDelete = (index: number) => {
    setSteps(steps.filter((_, i) => i !== index));
  };

  return (
    <Box textAlign="center" mt={8} p={6} borderWidth={1} borderRadius="lg" boxShadow="sm">
    <Box mb={4}>
      <VerticalTimeline layout="1-column-left" className="demoCustomLine" lineColor="#ccc">
        {steps.map((step, index) => (
          <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            contentStyle={{
              border: '1px solid #ccc',
              boxShadow: 'none',
              color: '#000',
            }}
            contentArrowStyle={{
              borderRight: '7px solid rgb(33, 150, 243)',
            }}
            icon={<StepIcon stepCount={index + 1} />}
            iconStyle={{ background: 'none', color: 'inherit' }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
              }}
            >
              <Text fontSize="20">{String.fromCharCode(65 + index)}</Text>
              <Text fontSize="20" fontWeight="bold">{step.waitTime}</Text>
              <Text fontSize="20" fontWeight="bold">{step.subject}</Text>
              <Text fontSize="20" fontWeight="bold">{step.emodProfile}</Text>
              <Text fontSize="20" fontWeight="bold">{step.body}</Text>
              <Box display="flex" alignItems="center">
                <Switch
                  size="md"
                  isChecked={step.active}
                  onChange={() => handleToggle(index)}
                  colorScheme="blue"
                  mr={3}
                />
                <IconButton
                  aria-label="Edit step"
                  icon={<EditIcon />}
                  onClick={() => handleEdit(index)}
                  size="sm"
                  mr={2}
                />
                <IconButton
                  aria-label="Duplicate step"
                  icon={<CopyIcon />}
                  onClick={() => handleDuplicate(index)}
                  size="sm"
                  mr={2}
                />
                <IconButton
                  aria-label="Delete step"
                  icon={<DeleteIcon />}
                  onClick={() => handleDelete(index)}
                  size="sm"
                  colorScheme="red"
                />
              </Box>
            </Box>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </Box>
  </Box>
  );
}

import { postApiCaller } from "./apiCaller";

export const getOptions = async (fieldName) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      fieldName: fieldName,
    };

    const response = await postApiCaller("api/commonfields/get-fields", body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
  }
};

export const addCampaigns = async (
  campaignName,
  description,
  summary,
  email,
  selectedDate,
  assignGroupId
) => {
  try {
    const accessToken = sessionStorage.getItem("accessToken");
    const userData = JSON.parse(sessionStorage?.getItem("userData"));
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const body = {
      name: campaignName,
      description: description,
      summary: summary,
      senderName: `${userData?.firstName} ${userData?.lastName}`,
      senderEmail: email,
      scheduledSendTime: selectedDate,
      assignGroups: [assignGroupId],
    };

    const response = await postApiCaller("api/campaigns/add", body, {
      headers,
    });

    return response;
  } catch (error) {
    console.error("Error fetching contact data:", error);
    return { error: error.message }; // Return error message or object
  }
};

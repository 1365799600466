import React, { useState } from "react";
import {
  Box,
  Button,
  Icon,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Switch,
  IconButton,
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon, CopyIcon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./AddSteps.css";

// Define the props type for StepIcon
interface StepIconProps {
  stepCount: number;
}

// Custom Icon Component
const StepIcon: React.FC<StepIconProps> = ({ stepCount }) => (
  <Box
    borderRadius="50%"
    backgroundColor="#3965FF"
    color="#fff"
    display={"flex"}
    margin={"auto"}
    alignItems={"center"}
    justifyContent={"center"}
    fontWeight={"bold"}
    w={"100%"}
    h={"100%"}
  >
    {stepCount}
  </Box>
);

export default function AddSteps() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");
  const [waitTime, setWaitTime] = useState("");
  const [emodProfile, setEmodProfile] = useState("");
  const [steps, setSteps] = useState<
    {
      waitTime: string;
      subject: string;
      emodProfile: string;
      body: string;
      date: string;
      active: boolean;
    }[]
  >([]);

  const handleSave = () => {
    // Add the new step to the steps array
    setSteps([
      ...steps,
      {
        waitTime,
        subject,
        emodProfile,
        body,
        date: new Date().toLocaleDateString(), // Add current date for demonstration
        active: true, // Default to active
      },
    ]);
    // Close the modal
    onClose();
    // Clear the form inputs
    setBody("");
    setSubject("");
    setWaitTime("");
    setEmodProfile("");
  };

  const handleToggle = (index: number) => {
    setSteps(
      steps.map((step, i) =>
        i === index ? { ...step, active: !step.active } : step
      )
    );
  };

  const handleEdit = (index: number) => {
    const step = steps[index];
    setWaitTime(step.waitTime);
    setSubject(step.subject);
    setEmodProfile(step.emodProfile);
    setBody(step.body);
    onOpen();
    // Remove the current step to replace it after editing
    setSteps(steps.filter((_, i) => i !== index));
  };

  const handleDuplicate = (index: number) => {
    setSteps([...steps, { ...steps[index] }]);
  };

  const handleDelete = (index: number) => {
    setSteps(steps.filter((_, i) => i !== index));
  };

  return (
    <Box
      textAlign="center"
      mt={8}
      p={6}
      borderWidth={1}
      borderRadius="lg"
      boxShadow="sm"
    >
      <Box mb={4}>
        {steps.length > 0 && (
          <VerticalTimeline
            layout={"1-column-left"}
            className="demoCustomLine"
            lineColor={"#ccc"}
          >
            {steps.map((step, index) => (
              <VerticalTimelineElement
                key={index}
                className="vertical-timeline-element--work"
                contentStyle={{
                  border: "1px solid #ccc",
                  boxShadow: "none",
                  color: "#000",
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  rgb(33, 150, 243)",
                }}
                icon={<StepIcon stepCount={index + 1} />} // Show step count in the icon
                iconStyle={{ background: "none", color: "inherit" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <Text variant="span" fontSize="20">
                    {String.fromCharCode(65 + index)}{" "}
                    {/* Step sequence: A, B, C, etc. */}
                  </Text>

                  <Text variant="span" fontSize="20" fontWeight={"bold"}>
                    {step.waitTime}
                  </Text>
                  <Text variant="span" fontSize="20" fontWeight={"bold"}>
                    {step.subject}
                  </Text>
                  <Text variant="span" fontSize="20" fontWeight={"bold"}>
                    {step.emodProfile}
                  </Text>
                  <Text variant="span" fontSize="20" fontWeight={"bold"}>
                    {step.body}
                  </Text>
                  <Box display="flex" alignItems="center">
                    <Switch
                      size="md"
                      isChecked={step.active}
                      onChange={() => handleToggle(index)}
                      colorScheme="blue"
                      mr={3}
                    />
                    <IconButton
                      aria-label="Edit step"
                      icon={<EditIcon />}
                      onClick={() => handleEdit(index)}
                      size="sm"
                      mr={2}
                    />
                    <IconButton
                      aria-label="Duplicate step"
                      icon={<CopyIcon />}
                      onClick={() => handleDuplicate(index)}
                      size="sm"
                      mr={2}
                    />
                    <IconButton
                      aria-label="Delete step"
                      icon={<DeleteIcon />}
                      onClick={() => handleDelete(index)}
                      size="sm"
                      colorScheme="red"
                    />
                  </Box>
                </Box>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        )}
      </Box>

      <Box display="flex" justifyContent="center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-clipboard-plus"
          width="70"
          height="70"
          viewBox="0 0 24 24"
          strokeWidth="0.8"
          stroke="#2c3e50"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
          <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
          <path d="M10 14h4" />
          <path d="M12 12v4" />
        </svg>
      </Box>
      <Button onClick={onOpen} colorScheme="blue" mt={4}>
        <Icon as={AddIcon} mx={2} color="white" />
        Add Campaign Step
      </Button>

      {/* Modal */}
      <Modal isOpen={isOpen} size="xl" onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Steps</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="waitTime" mb={4}>
              <FormLabel>Wait Time</FormLabel>
              <NumberInput
                value={waitTime}
                onChange={(value) => setWaitTime(value)}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>

            <FormControl id="subject" mb={4}>
              <FormLabel>Subject</FormLabel>
              <Input
                type="text"
                placeholder="Enter subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </FormControl>

            <FormControl id="emodProfile" mb={4}>
              <FormLabel>eMod Profile</FormLabel>
              <Select
                placeholder="Select eMod profile"
                value={emodProfile}
                onChange={(e) => setEmodProfile(e.target.value)}
              >
                {/* Add profile options here */}
                <option value="profile1">Profile 1</option>
                <option value="profile2">Profile 2</option>
              </Select>
            </FormControl>

            <FormControl id="body" mb={4}>
              <FormLabel>Body</FormLabel>
              <ReactQuill
                value={body}
                onChange={setBody}
                theme="snow"
                placeholder="Enter body content"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Save
            </Button>
            <Button variant="outline" borderColor={"#ccc"} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

import React from 'react';
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, ChevronRightIcon } from "@chakra-ui/icons";

export default function EmailDetails() {
  return (
    <Box

   p={6} borderWidth={1} borderRadius="lg" boxShadow="sm"
    >
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Title Name</Th>
              <Th>From Email</Th>
              <Th>SMTP Email</Th>
              <Th>SMTP Host</Th>
              <Th>SMTP Port</Th>
              <Th>IMAP Email</Th>
              <Th>IMAP Host</Th>
              <Th>IMAP Port</Th>
              <Th>Throttle Starting Volume</Th>
              <Th>Throttle Ending Volume</Th>
              <Th>Email Delay</Th>
              <Th>Days Till Full Throttle</Th>
              <Th>Today's Potential Volume</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Example Name</Td>
              <Td>Example Title</Td>
              <Td>example@example.com</Td>
              <Td>smtp@example.com</Td>
              <Td>smtp.example.com</Td>
              <Td>587</Td>
              <Td>imap@example.com</Td>
              <Td>imap.example.com</Td>
              <Td>993</Td>
              <Td>100</Td>
              <Td>1000</Td>
              <Td>5 seconds</Td>
              <Td>3 days</Td>
              <Td>500</Td>
              <Td>
                <IconButton
                  aria-label="Edit"
                  icon={<EditIcon />}
                  mr={2}
                />
                <IconButton
                  aria-label="Delete"
                  icon={<DeleteIcon />}
                />
              </Td>
            </Tr>
            {/* Add more rows as needed */}
          </Tbody>
        </Table>
      </TableContainer>

      <Button
        display={"flex"}
        justifyContent="flex-end"
        marginLeft="auto"
        width="auto"
        colorScheme="blue"
        mt={4}
        rightIcon={<ChevronRightIcon />}
      >
        Save & Next
      </Button>
    </Box>
  );
}

import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Icon,
  Tooltip,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  IconButton,
  TableContainer,
  TabPanel,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { FaPlus } from "react-icons/fa";
import AddCampaignDefault from "../AddCampaignDefault/AddCampaignDefault";
import { getAllCampaigns } from "libs/apiFunctions";
import ScratchCampaigns from "../AddCampaignDefault/ScratchCampaigns/ScratchCampaigns";
import { FaEye, FaTrash } from 'react-icons/fa'; // Importing icons

const CampaignsTable = (props: { tableData: any }) => {
  const { tableData } = props;
  const [campaingsData, setCampaignsData] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showScratch, setShowScratch] = useState(false);

  const textColor = useColorModeValue("secondaryGray.900", "white");

  const fetchCampain = async () => {
    let response = await getAllCampaigns();
    setCampaignsData(response?.campaigns || []);
  };

  useEffect(() => {
    fetchCampain();
  }, []);

  const handleAddCampaign = (newCampaign: any) => {
    setCampaignsData((prevCampaigns) => [...prevCampaigns, newCampaign]);
  };

  const handleSwitchToScratch = () => {
    setShowScratch(true);
  };

  const handleBackToDefault = () => {
    setShowScratch(false);
  };

  return (
    <>
      <Box px="0px" justifyContent="space-between" my="0px">
        {!showScratch && (
       <Box
       borderRadius='16px'
       // border='1px solid'
       p={4}
       bgColor='#fff'
       overflow='hidden'>
            <Tabs variant="brand">
              <TabList
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Box sx={{ display: "flex" }}>
                  <Tab
                    sx={{
                      boxShadow: "none",
                      "&:focus": {
                        boxShadow: "none",
                      },
                      "&.chakra-tabs__tab[aria-selected='true']": {
                        color: "white",
                        fontWeight: "bold",
                        border: "2px solid blue",
                        borderRadius: "20px",
                        borderBottomColor: "blue",
                        backgroundColor: "#422AFB",
                      },
                    }}
                  >
                    Total Campaigns
                  </Tab>
                  <Tab
                    sx={{
                      boxShadow: "none",
                      "&:focus": {
                        boxShadow: "none",
                      },
                      "&.chakra-tabs__tab[aria-selected='true']": {
                        color: "white",
                        fontWeight: "bold",
                        border: "2px solid blue",
                        borderRadius: "20px",
                        borderBottomColor: "blue",
                        backgroundColor: "#422AFB",
                      },
                    }}
                  >
                    Total Contacts
                  </Tab>
                  <Tab
                    sx={{
                      boxShadow: "none",
                      "&:focus": {
                        boxShadow: "none",
                      },
                      "&.chakra-tabs__tab[aria-selected='true']": {
                        color: "white",
                        fontWeight: "bold",
                        border: "2px solid blue",
                        borderRadius: "20px",
                        borderBottomColor: "blue",
                        backgroundColor: "#422AFB",
                      },
                    }}
                  >
                    Total Email Sent
                  </Tab>
                </Box>
                <Box display="flex" alignItems="center">
                  <Tooltip
                    label="Create Campaigns"
                    borderRadius={8}
                    fontSize="sm"
                  >
                    <Button
                      alignItems="center"
                      justifyContent="center"
                      variant="brand"
                      mx={2}
                      color="white"
                      lineHeight="100%"
                      borderRadius="30px"
                      onClick={() => setIsOpen(true)}
                    >
                      <Icon
                        as={FaPlus}
                        color="white"
                        w="20px"
                        h="20px"
                        mx="2"
                      />
                      Create Campaign
                    </Button>
                  </Tooltip>
                </Box>
              </TabList>

              <TabPanels p="0px!important">
                <TabPanel p="0px!important">
                  {campaingsData.length === 0 ? (
                    <Flex justify="center" align="center" height="200px">
                      <Spinner size="lg" />
                      <Text ml="4" fontSize="lg" color={textColor}>
                        Loading...
                      </Text>
                    </Flex>
                  ) : (
                    <TableContainer mt={8}>
        <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Description</Th>
                          <Th>Sender Name</Th>
                          <Th>Scheduled Send Time</Th>
                          <Th>Actions</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {campaingsData.map((campaign, index) => (
                          <Tr key={index}>
                            <Td>{campaign.name}</Td>
                            <Td>{campaign.description}</Td>
                            <Td>{campaign.senderName}</Td>
                            <Td>{campaign.scheduledSendTime?.split("T")[0]}</Td>
                            <Td>
            <IconButton
              icon={<FaEye />}
              aria-label="View"
              onClick={() => console.log("View:", campaign)}
            />
            <IconButton
              icon={<FaTrash />}
              aria-label="Delete"
              onClick={() => console.log("Delete:", campaign)}
              ml={2}
            />
          </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                    </TableContainer>
                  )}
                </TabPanel>
                <TabPanel>
                  <p>two!</p>
                </TabPanel>
                <TabPanel>
                  <p>three!</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        )}

        {showScratch && (
          <Box>
            <Button
              onClick={handleBackToDefault}
              colorScheme="blue"
              mb="1"
              leftIcon={<ArrowBackIcon />}
            >
              Back to Previous
            </Button>
            {/* Your scratch content here */}
            <ScratchCampaigns />
          </Box>
        )}
      </Box>

      <AddCampaignDefault
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onAddCampaign={handleAddCampaign}
        fetchCampain={fetchCampain}
        onSwitchToScratch={handleSwitchToScratch}
      />
    </>
  );
};

export default CampaignsTable;

// Chakra imports
import { Text, useColorModeValue } from '@chakra-ui/react';
// Assets
import Project1 from 'assets/img/profile/Project1.png';
import Project2 from 'assets/img/profile/Project2.png';
import Project3 from 'assets/img/profile/Project3.png';
// Custom components
import Card from 'components/card/Card';
import Project from 'views/admin/profile/components/Project';

export default function TemplateList(props: { [x: string]: any }) {
	const { ...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const textColorSecondary = 'gray.400';
	const cardShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
	return (
		<Card px={0} py={0} {...rest}>
			<Text color={'brand'} fontWeight='bold' fontSize='1xl' mt='10px' mb='20px'>
				All Templates List
			</Text>
			<Project
				boxShadow={cardShadow}
				mb='20px'
				image={Project1}
				ranking='1'
				link='#'
				title='Technology behind the Blockchain'
			/>
			<Project
				boxShadow={cardShadow}
				mb='20px'
				image={Project2}
				ranking='2'
				link='#'
				title='Greatest way to a good Economy'
			/>
			<Project
				boxShadow={cardShadow}
				image={Project3}
				ranking='3'
				link='#'
				title='Most essential tips for Burnout'
			/>
		</Card>
	);
}

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiFillSetting } from "react-icons/ai";
import { BsCircleFill, BsFillEnvelopeFill } from "react-icons/bs";
import { FaCube, FaUserPlus, FaEye } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import AddCampaignDetails from "./CampaignInnerSteps/AddCampaignDetails/AddCampaignDetails";
import EmailDetails from "./CampaignInnerSteps/EmailDetails/EmailDetails";
import AddContacts from "./CampaignInnerSteps/AddContacts/AddContacts";
import AddSteps from "./CampaignInnerSteps/AddSteps/AddSteps";
import PreviewSteps from "./CampaignInnerSteps/PreviewSteps/PreviewSteps";
import axios from "axios";
import { toast } from "react-toastify";
import { postApiCaller } from "libs/apiCaller";

// Define step icons as components
const StepIcons = {
  addCampaignDetails: FaCube,
  emailDetails: BsFillEnvelopeFill,
  addContacts: FaUserPlus,
  addStep: MdModeEdit,
  preview: FaEye,
};

function CampaignWizard() {
  const textColor = useColorModeValue("gray.700", "white");
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    { icon: StepIcons.addCampaignDetails, label: "Add Campaign Details", },
    { icon: StepIcons.emailDetails, label: "Email Details" },
    { icon: StepIcons.addContacts, label: "Add Contacts" },
    { icon: StepIcons.addStep, label: "Add Step" },
    { icon: StepIcons.preview, label: "Preview" },
  ];

  let ClientID = "3459867944-5lkfcss8q0619b1m9u65tf2bt9anu6h3.apps.googleusercontent.com"
  let UserID = ""

  const SCOPE = "https://mail.google.com https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/contacts.other.readonly https://www.googleapis.com/auth/gmail.settings.basic https://www.googleapis.com/auth/gmail.settings.sharing"
  const REDIRECT_URL = "http://localhost:3000/accounts/authCallback"
  const CLIENT_ID = "3459867944-abiro4ir0pu0imrmk1f8srapc0f4j0tf.apps.googleusercontent.com"

  // start Authenticate email
  const handleConfigure = async () => {
    try {
      // const data = {
      //   ClientID: ClientID,
      //   UserID: UserID,
      // };
  
      // Axios POST request
      // const response = await postApiCaller();
      // const response = await axios.post(
      //   `https://www.googleapis.com/auth/userinfo.profile`,
      //   data
      // );

      const response = "SUCCESS"
  
      if (response === "SUCCESS") {
        const loginHint = ''; // Set loginHint to empty string or required value
        const scope = encodeURIComponent(SCOPE);
        const redirect_uri_encode = encodeURIComponent(REDIRECT_URL);
        const client_id = encodeURIComponent(CLIENT_ID);
        const response_type = "code";
        const access_type = "offline";
        const state = ``;
        const approval_prompt = "force";
  
        // Construct the Google OAuth URL
        const Url = `https://accounts.google.com/o/oauth2/auth?scope=${scope}&redirect_uri=${redirect_uri_encode}&response_type=${response_type}&client_id=${client_id}&state=${state}&access_type=${access_type}&approval_prompt=${approval_prompt}&login_hint=${loginHint}`;
  
        // Redirect to the constructed URL
        window.location.href = Url;
      } else {
        toast.error(response || "An error occurred.");
      }
    } catch (error) {
      console.error("Error during configuration:", error);
      toast.error("Failed to configure the account. Please try again.");
    }
  };
  
  // end Authenticate email


  return (
    <Flex direction="column" minH="100vh" align="center" my={6}>
      <Box
        w="100%"
        p="20px"
        borderRadius="20px"
        py='10px'
        bg="white"
        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Text
              my={4}
              mt={2}
              fontSize={22}
              fontWeight={"semibold"}
              colorScheme="blue"
            >
              Create Campaign
            </Text>
          </div>
          <Button
            display={"flex"}
            justifyContent="flex-end"
            marginLeft="auto"
            width="auto"
            colorScheme="blue"
            mt={4}
            onClick={handleConfigure}
          >
            Configure
          </Button>
        </div>

        <Tabs
          index={activeStep}
          onChange={(index) => setActiveStep(index)}
          variant="unstyled"
        >
          <TabList
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb="0" // Remove bottom margin
            p="0" // Remove padding
          >
            {steps.map((step, index) => (
              <Tab
                borderRight={
                  index < steps.length - 1 ? "1px solid #CBD5E0" : "none"
                } // Remove border for the last tab
                key={index}
                _focus={{ outline: "none" }}
                onClick={() => setActiveStep(index)}
                bg={activeStep === index ? "#3965FF" : "#ecf2f6"} // Set background color
                _hover={{ bg: activeStep === index ? "#3965FF" : "#d3e1e8" }} // Hover effect
                m="0" // Remove margin
                flex="1" // Ensure tabs fill the available space
              >
                <Flex
                  direction="column"
                  justify="center"
                  align="center"
                  position="relative"
                >
                  <Icon
                    as={step.icon}
                    color={activeStep === index ? "white" : "gray.500"} // Adjust icon color
                    w={activeStep === index ? "24px" : "20px"}
                    h={activeStep === index ? "24px" : "20px"}
                    mb="8px"
                  />
                  <Text
                    color={activeStep === index ? "white" : "gray.500"} // Adjust text color
                    fontWeight={activeStep === index ? "bold" : "normal"}
                    fontSize="sm"
                  >
                    {step.label}
                  </Text>
                </Flex>
              </Tab>
            ))}
          </TabList>
          <TabPanels py={5}>
            <TabPanel p="0">
              {" "}
              {/* Remove padding */}
              <AddCampaignDetails />
            </TabPanel>
            <TabPanel p="0">
              {" "}
              {/* Remove padding */}
              <EmailDetails />
            </TabPanel>
            <TabPanel p="0">
              <AddContacts />
            </TabPanel>
            <TabPanel p="0">
              {" "}
              {/* Remove padding */}
              <AddSteps />
            </TabPanel>
            <TabPanel p="0">
              {" "}
              <PreviewSteps />
            </TabPanel>
          </TabPanels>
        </Tabs>

      </Box>

    </Flex>
  );
}

export default CampaignWizard;

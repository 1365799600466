import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  Textarea,
  VStack,
  SimpleGrid,
  Heading,
  Divider,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { getOptions } from "libs/campaignApi";

// Define the options to fetch and their corresponding labels
const optionConfigs = [
  { key: "CampaignPriority", label: "Priority" },
  { key: "EmailOpenTracking", label: "Open Tracking" },
  { key: "eModProfile", label: "eMod Profile" },
  { key: "ReplyBehavior", label: "Reply Behaviour" },
];

interface CampaignOption {
  _id: string;   
  fieldTypeName: string;
  fieldID: number;
  fieldName: string;
  comment: string | null;
  isDeleted: boolean;
  isActive: boolean;
  orderBy: number;
  createdDate: string;
  lastModifiedDate: string;
}

export default function AddCampaignDetails() {
  const [optionsData, setOptionsData] = useState<Record<string, string[]>>({
    CampaignPriority: [],
    EmailOpenTracking: [],
    eModProfile: [],
    ReplyBehavior: [],
  });

  console.log("options data ", JSON.stringify(optionsData));

  // Fetch options for each configured field
  const fetchOptions = async () => {
    try {
      const responses = await Promise.all(
        optionConfigs.map(async (config) => {
          const response: CampaignOption[] = await getOptions(config.key); // Specify the type here

          return {
            key: config.key,
            options: response
              ? response.filter((item: CampaignOption) => item.isActive)
                .map((item: CampaignOption) => item.fieldName)
              : [], // Default to an empty array if response is falsy
          };
        })
      );

      const mappedOptions = responses.reduce((acc, { key, options }) => {
        acc[key] = options; // No need to check, we ensure it's an array in the previous step
        return acc;
      }, {} as Record<string, string[]>); // Adjusted the type to string[] since we're dealing with fieldNames

      setOptionsData(mappedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <Box p={6} borderWidth={1} borderRadius="lg" boxShadow="sm">
      <Stack spacing={6}>
        {/* Campaign Details Group */}
        <VStack align="stretch" spacing={4}>
          <Heading size="md">Campaign Details</Heading>
          <Divider />
          <SimpleGrid columns={2} spacing={10}>
            <FormControl id="campaign-name">
              <FormLabel>Campaign Name</FormLabel>
              <Input type="text" placeholder="Enter campaign name" />
            </FormControl>

            {/* Use mapped options for Reply Behaviour */}
            <FormControl id="reply-behaviour">
              <FormLabel>Reply Behaviour</FormLabel>
              <Select placeholder="Select reply behaviour">
                {optionsData["ReplyBehavior"].map((option) => (
                  <option key={option} value={option}>
                    {option} {/* Use the string directly for the label */}
                  </option>
                ))}
              </Select>
            </FormControl>
          </SimpleGrid>

          <SimpleGrid columns={2} spacing={10}>
            {/* Use mapped options for Priority */}
            <FormControl id="priority">
              <FormLabel>Priority</FormLabel>
              <Select placeholder="Select priority">
                {optionsData["CampaignPriority"].map((option) => (
                  <option key={option} value={option}>
                    {option} {/* Use the string directly for the label */}
                  </option>
                ))}
              </Select>
            </FormControl>

            {/* Use mapped options for Open Tracking */}
            <FormControl id="open-tracking">
              <FormLabel>Open Tracking</FormLabel>
              <Select placeholder="Select open tracking">
                {optionsData["EmailOpenTracking"].map((option) => (
                  <option key={option} value={option}>
                    {option} {/* Use the string directly for the label */}
                  </option>
                ))}
              </Select>
            </FormControl>
          </SimpleGrid>

          {/* Use mapped options for eMod Profile */}
          <SimpleGrid columns={2} spacing={10}>
            <FormControl id="emod-profile">
              <FormLabel>eMod Profile</FormLabel>
              <Select placeholder="Select eMod profile">
                {optionsData["eModProfile"].map((option) => (
                  <option key={option} value={option}>
                    {option} {/* Use the string directly for the label */}
                  </option>
                ))}
              </Select>
            </FormControl>
          </SimpleGrid>
        </VStack>

        {/* Options Group */}
        <VStack align="stretch" spacing={4}>
          <Heading size="md">Options</Heading>
          <Divider />
          <SimpleGrid columns={2} spacing={10}>
            <FormControl id="send-on-weekends">
              <Checkbox>Send on Weekends</Checkbox>
            </FormControl>

            <FormControl id="ignore-blacklist">
              <Checkbox>Ignore Blacklist</Checkbox>
            </FormControl>
          </SimpleGrid>
          <SimpleGrid columns={2} spacing={10}>
            <FormControl id="track-clicks">
              <Checkbox>Track Clicks</Checkbox>
            </FormControl>

            <FormControl id="subject-prompt">
              <Checkbox>Subject Prompt</Checkbox>
            </FormControl>
          </SimpleGrid>
        </VStack>

        {/* Notes Group */}
        <VStack align="stretch" spacing={4}>
          <Heading size="md">Additional Notes</Heading>
          <Divider />
          <FormControl id="notes">
            <FormLabel>Notes</FormLabel>
            <Textarea placeholder="Enter any additional notes" />
          </FormControl>
        </VStack>

        {/* Save Button */}
      </Stack>
      <Button
        display={"flex"}
        justifyContent="flex-end"
        marginLeft="auto"
        width="auto"
        colorScheme="blue"
        mt={4}
        rightIcon={<ChevronRightIcon />}
      >
        Save & Next
      </Button>
    </Box>
  );
}
